const ATTRIBUTES_AUTOS = {
  ATV_FUEL_TYPE: 'atvfueltype',
  ATV_MAKE: 'atvmake',
  ATV_MODEL: 'atvmodel',
  ATV_TRANSMISSION: 'atvtransmission',
  ATV_TRIM: 'atvtrim',
  ATV_TYPE: 'atvtype',
  BOATS_ENGINE_POWER: 'boatsenginepower',
  BOATS_LENGTH: 'boatslength',
  BOATS_MAKE: 'boatsmake',
  BOATS_MODEL: 'boatsmodel',
  BOATS_TRIM: 'boatstrim',
  BOAT_MAKE: 'boatsmake',
  CAB_TYPE: 'cabtype',
  CAMPERS_FUEL_TYPE: 'campersfueltype',
  CAMPERS_TRIM: 'camperstrim',
  CANOES_TYPE: 'canoestype',
  CARFAX: 'carprooflink',
  CAR_BODY_TYPE: 'carbodytype',
  CAR_COLOR: 'carcolor',
  CAR_ENGINE: 'carenginedisplacement',
  CAR_ENGINE_POWER: 'chtruckenginepower',
  CAR_FUEL_TYPE: 'carfueltype',
  CAR_INTERIOR_COLOR: 'carinteriorcolor',
  CAR_MAKE: 'carmake',
  CAR_MILEAGE: 'carmileageinkms',
  CAR_MODEL: 'carmodel',
  CAR_TRANSMISSION: 'cartransmission',
  CAR_TRIM: 'cartrim',
  CAR_YEAR: 'caryear',
  CONDITION: 'condition',
  CPO: 'cpoprogram',
  CYLINDERS: 'cylinders',
  DRIVE_TRAIN: 'drivetrain',
  ELECTRIC_RANGE: 'electricrange',
  FARMING_EQUIPMENT_FUEL_TYPE: 'farmingequipmentfueltype',
  FARMING_EQUIPMENT_TRIM: 'farmingequipmenttrim',
  FUEL_CAPACITY: 'fuelcapacity',
  HEAVY_EQUIPMENT_ENGINE_POWER: 'heavyequipenginepower',
  HEAVY_EQUIPMENT_FUEL_TYPE: 'heavyequipfueltype',
  HEAVY_EQUIPMENT_TRIM: 'heavyequiptrim',
  HEAVY_EQUIPMENT_TYPE: 'heavyequiptype',
  HOURS: 'hours',
  MATERIAL: 'material',
  MOTORBOATS_FUEL_TYPE: 'motorboatsfueltype',
  MOTORBOATS_TYPE: 'motorboatstype',
  MOTORCYCLE_DRIVE_TRAIN: 'motorcyclesdrivetrain',
  MOTORCYCLE_ENGINE_POWER: 'motorcyclesenginepower',
  MOTORCYCLE_FUEL_TYPE: 'motorcyclesfueltype',
  MOTORCYCLE_MAKE: 'motorcyclesmake',
  MOTORCYCLE_MODEL: 'motorcyclesmodel',
  MOTORCYCLE_TRANSMISSION: 'motorcyclestransmission',
  MOTORCYCLE_TRIM: 'motorcyclestrim',
  MOTORHOMES_FUEL_TYPE: 'motorhomesfueltype',
  MOTORHOMES_HEIGHT: 'motorhomesheight',
  MOTORHOMES_LENGTH: 'motorhomeslength',
  MOTORHOMES_TRIM: 'motorhomestrim',
  MOTORHOMES_TYPE: 'motorhomestype',
  MOTORHOMES_WEIGHT: 'motorhomesweight',
  MOTORHOME_ENGINE_POWER: 'motorhomesenginepower',
  NUM_DOORS: 'noofdoors',
  NUM_SEATS: 'noofseats',
  PARK_MODELS_TRIM: 'parkmodelstrim',
  PASSENGERS: 'passengers',
  PROPULSION: 'propulsion',
  PWC_FUEL_TYPE: 'pwcfueltype',
  ROOMS: 'noofrooms',
  SAILBOATS_FUEL_TYPE: 'sailboatsfueltype',
  SAILBOATS_TYPE: 'sailboatstype',
  SEATS: 'seats',
  SLEEPING_CAPACITY: 'sleepingcapacity',
  SLIDEOUTS: 'slideouts',
  SNOW_ENGINE_POWER: 'snowenginepower',
  SNOW_FUEL_TYPE: 'snowfueltype',
  SNOW_TRIM: 'snowtrim',
  SNOW_TYPE: 'snowtype',
  SPEEDS: 'speeds',
  STOCK: 'stock',
  TIME_TO_CHARGE: 'timetocharge',
  TRAILERS_TRIM: 'trailerstrim',
  TRUCK_FUEL_TYPE: 'chtruckfueltype',
  TRUCK_TRIM: 'chtrucktrim',
  VEHICLE_TYPE: 'vehicletype',
  VIN: 'vin',
  WHEELS: 'wheels',
  /**
   * Vehicle Features
   * Those values are a part of a group returned from the BE
   * ANVIL should extract them from the attributes array and send them separately
   */
  AIR_CONDITIONING: 'airconditioning',
  ALLOY_WHEELS: 'alloywheels',
  AMENITIES: 'amenities',
  APPLIANCES: 'appliances',
  AUDIO_SYSTEM: 'audiosystem',
  BLUETOOTH: 'bluetooth',
  CARGO_RACKS: 'cargoracks',
  CRUISE_CONTROL: 'cruisecontrol',
  ELECTRONIC_REVERSE_CONTROL: 'electronicreversecontrol',
  ENTERTAINMENT_SYSTEM: 'entertainmentsystem',
  FIRE_EXTINGUISHER: 'fireextinguisher',
  GENERATOR: 'generator',
  LIFE_RAFT: 'liferaft',
  NAV_SYSTEM: 'navsystem',
  PARKING_ASSIST: 'parkingassist',
  PUSH_BUTTON_START: 'pushbuttonstart',
  REVERSE_GEAR: 'reversegear',
  SATELLITE_RADIO: 'satelliteradio',
  STEREO: 'stereo',
  STORAGE: 'storage',
  SUNROOF: 'sunroof',
  TRAILER_HITCH: 'trailerhitch',
  TRAILER_INCLUDED: 'trailerincluded',
  WINCH: 'winch',
  WIRELESS_CHARGING: 'wirelesscharging',
} as const

const ATTRIBUTES_RE = {
  ACCESSIBLE_WASHROOMS: 'accessiblewashroomsinsuite',
  AGREEMENT_TYPE: 'agreementtype',
  AIR_CONDITIONING: 'airconditioning',
  AREA_IN_FEET: 'areainfeet',
  AUDIO_PROMPTS: 'audioprompts',
  BALCONY: 'balcony',
  BARRIER_FREE: 'barrierfreeentrancesandramps',
  BATHROOMS: 'numberbathrooms',
  BEDROOMS: 'numberbedrooms',
  BICYCLE_PARKING: 'bicycleparking',
  BRAILLE_LABELS: 'braillelabels',
  CABLE_TV: 'cabletv',
  CONCIERGE: 'concierge',
  DATE_AVAILABLE: 'dateavailable',
  DISHWASHER: 'dishwasher',
  ELEVATOR: 'elevator',
  FOR_RENT_BY_HOUSING: 'forrentbyhousing',
  FRIDGE: 'fridgefreezer',
  FURNISHED: 'furnished',
  GYM: 'gym',
  HEAT: 'heat',
  HYDRO: 'hydro',
  INTERNET: 'internet',
  LAUNDRY_BUILDING: 'laundryinbuilding',
  LAUNDRY_UNIT: 'laundryinunit',
  PARKING: 'numberparkingspots',
  PET_FRIENDLY: 'petsallowed',
  POOL: 'pool',
  RENTALS_VIRTUAL_OPTIONS: 'rentalsvirtualoptions',
  SMOKING_PERMITTED: 'smokingpermitted',
  STORAGE_LOCKER: 'storagelocker',
  TERM_AGREEMENT: 'termagreement',
  TRAILERS_HITCH: 'trailerhitch',
  TWENTY_FOUR_HOUR_SECURITY: 'twentyfourhoursecurity',
  UNIT_TYPE: 'unittype',
  VISUAL_AIDS: 'visualaids',
  WATER: 'water',
  WHEELCHAIR_ACCESSIBLE: 'wheelchairaccessible',
  YARD: 'yard',
} as const

const ATTRIBUTES_PETS = {
  BIRTHDAY: 'birthdate',
  BIRTH_DATE: 'birthdate',
  BREED: 'breed',
  GENDER: 'sex',
} as const

const ATTRIBUTES_BUY_SELL = {
  BRAND_DESKTOP: 'desktopbrand',
  BRAND_LAPTOP: 'laptopbrand',
  BRAND_PHONE: 'phonebrand',
  BRAND_TABLET: 'tabletbrand',
  CASHLESS_PAYMENT: 'cashless',
  CASH_ACCEPTED: 'cashaccepted',
  CURBSIDE: 'curbside',
  DROP_OFF: 'dropoff',
  FRAME_SIZE: 'framesize',
  JEWELLERY_TYPE: 'jewellerytype',
  PHONE_CARRIER: 'phonecarrier',
  SCREEN_SIZE_LAPTOP: 'laptopscreensize',
  SHIPPING: 'shipping',
  SIZE: 'size',
}

const ATTRIBUTES_JOBS = {
  COMPANY: 'company',
  JOB_TYPE: 'jobtype',
  JOB_OFFERED_BY: 'jobofferedby',
  APPLICATION_LINK: 'applicationlink',
}

const ATTRIBUTES_VACATION_RENTALS = {
  AVAILABILITY_END_DATE: 'availabilityenddate',
  AVAILABILITY_START_DATE: 'availabilitystartdate',
  CITY: 'city',
  MIN_NIGHTS: 'minnights',
  SLEEPS: 'maxpeople',
  VACATION_TYPE: 'vacationtype',
}

const ATTRIBUTES_COMMON = {
  FOR_SALE_BY: 'forsaleby',
  FULFILLMENT: 'fulfillment',
  PAYMENT: 'payment',
  /**
   * Dealer Updates
   * Those values are a part of a group returned from the BE
   * ANVIL should extract them from the attributes array and send them separately
   */
  BY_APPOINTMENT_ONLY: 'byappointmentonly',
  HOME_DELIVERY: 'homedelivery',
  ONLINE_FINANCING: 'onlinefinancing',
  ONLINE_PURCHASING: 'onlinepurchasing',
  PAYMENT_DEFERRALS: 'paymentdeferrals',
  RETURN_POLICY: 'returnpolicy',
  SERVICE_DROPOFF: 'servicedropoff',
  SERVICE_OPEN: 'serviceopen',
  SHOWROOM_CLOSED: 'showroomclosed',
  SHOWROOM_OPEN: 'showroomopen',
  VIDEO_CHAT: 'videochat',
  VIRTUAL_APPRAISAL: 'virtualappraisal',
}

export const ATTRIBUTES = {
  ...ATTRIBUTES_COMMON,
  ...ATTRIBUTES_AUTOS,
  ...ATTRIBUTES_BUY_SELL,
  ...ATTRIBUTES_JOBS,
  ...ATTRIBUTES_PETS,
  ...ATTRIBUTES_RE,
  ...ATTRIBUTES_VACATION_RENTALS,
} as const

/**
 * This is a list of all attributes that should be rendered as a link
 */
export const ATTRIBUTES_URL_VALUE = [ATTRIBUTES.APPLICATION_LINK]

/** ATTRIBUTES VALUES */
export enum VEHICLE_TYPE_VALUES {
  NEW = 'new',
  USED = 'used',
  LEASE = 'lease',
  DAMAGED = 'damaged',
  SALVAGE = 'salvage',
}

export enum UNIT_TYPE_VALUES {
  APARTMENT = 'apartment',
  BASEMENT_APARTMENT = 'basement-apartment',
  CONDO = 'condo',
  DUPLEX = 'duplex-triplex',
  HOUSE = 'house',
  TOWNHOUSE = 'townhouse',
}

export enum FOR_SALE_BY_VALUES {
  DEALER = 'delr',
  OWNER = 'ownr',
}
export enum PET_FRIENDLY_VALUES {
  LIMITED = 'limited',
  FALSE = '0',
  TRUE = '1',
}
export enum PET_GENDER_VALUES {
  FEMALE = 'female',
  MALE = 'male',
  NOT_SPECIFIED = 'notspecified',
}

export enum BOOLEAN_NUMERIC_VALUES {
  YES = '1',
  NO = '0',
}

export enum BOOLEAN_TEXT_VALUES {
  YES = 'true',
  NO = 'false',
}

export enum CAR_TRANSMISSION_VALUES {
  MANUAL = '1',
  AUTOMATIC = '2',
  OTHER = '3',
  SEMI_AUTOMATIC = '0',
}
export enum CAR_FUEL_VALUES {
  DIESEL = 'diesel',
  ELECTRIC = 'electric',
  GAS = 'gas',
  HYBRID = 'hybrid',
  OTHER = 'other',
}

export enum CAR_BODY_TYPE_VALUES {
  CONVERTIBLE = 'conv',
  COUPE = 'coup',
  HATCHBACK = 'htchbck',
  PICKUP_TRUCK = 'pickuptruck',
  SEDAN = 'sedan',
  SUV_CROSSOVER = 'suvcrossover',
  VAN = 'vanminicomma',
  WAGON = 'wagon',
  OTHER = 'othrbdytyp',
}

export enum FULFILLMENT_VALUES {
  DELIVERY = 'delivery',
  SHIPPING = 'shipping',
  CURBSIDE = 'curbside',
}

export enum PAYMENT_VALUES {
  CASHLESS = 'cashless',
  CASH_ACCEPTED = 'cashaccepted',
}

export enum RENTALS_VIRTUAL_OPTIONS_VALUES {
  ONLINE_APPLICATION = 'onlineapplication',
  VIDEO_CHAT = 'videochat',
  VIDEO_TOUR = 'virtualtour',
}

export enum TERM_AGREEMENT_VALUES {
  RENT_DEFERRAL = 'rentdeferral',
}

export enum FOR_RENT_BY_HOUSING_VALUES {
  OWNER = 'ownr',
  PROFESSIONAL = 'reprofessional',
}

/** LABEL TRANSLATIONS */
export const CAR_TRANSMISSION_LABELS: Record<number, string> = {
  [CAR_TRANSMISSION_VALUES.MANUAL]: `listing:attributes.car_transmission.manual`,
  [CAR_TRANSMISSION_VALUES.AUTOMATIC]: `listing:attributes.car_transmission.automatic`,
  [CAR_TRANSMISSION_VALUES.OTHER]: `listing:attributes.car_transmission.other`,
}
