import { isAnyCarsVehiclesCategory, isAnyRealEstateCategory } from '@kijiji/category'

import { ATTRIBUTES } from '@/features/attributes/constants/attributes'
import { type AttributeConfig } from '@/features/attributes/constants/attributesConfig'
import { type LabelOverrideProps } from '@/features/attributes/constants/attributesLabelOverride'

/** Dealer updates are boolean values that should only be returned if true */
const labelOverride: LabelOverrideProps = ({ attribute: { canonicalName }, t }) =>
  t(`listing:attributes.dealer_updates.${canonicalName}`)

/**
 * The dealer updates fields are not autos-only they should appear for RE as well
 * Those are grouped by the BE as "Dealer Updates" fields
 */

export const DEALER_UPDATES_FIELDS: AttributeConfig = {
  [ATTRIBUTES.BY_APPOINTMENT_ONLY]: { labelOverride },
  [ATTRIBUTES.HOME_DELIVERY]: { labelOverride },
  [ATTRIBUTES.ONLINE_FINANCING]: { labelOverride },
  [ATTRIBUTES.ONLINE_PURCHASING]: { labelOverride },
  [ATTRIBUTES.PAYMENT_DEFERRALS]: { labelOverride },
  [ATTRIBUTES.RETURN_POLICY]: { labelOverride },
  [ATTRIBUTES.SERVICE_DROPOFF]: { labelOverride },
  [ATTRIBUTES.SERVICE_OPEN]: { labelOverride },
  [ATTRIBUTES.SHOWROOM_CLOSED]: { labelOverride },
  [ATTRIBUTES.SHOWROOM_OPEN]: { labelOverride },
  [ATTRIBUTES.VIDEO_CHAT]: { labelOverride },
  [ATTRIBUTES.VIRTUAL_APPRAISAL]: { labelOverride },
}

/**
 * Gets the Dealer Updates attributes for a given category ID
 *
 * @param categoryId - The ID of the category to get featured attributes for
 * @returns The dealer updates configuration for the category, or undefined if none exist
 */
export const getDealerUpdatesByCategoryId = (categoryId: number): AttributeConfig | undefined => {
  /** Only the verticals have dealer updates */
  if (isAnyRealEstateCategory(categoryId) || isAnyCarsVehiclesCategory(categoryId)) {
    return DEALER_UPDATES_FIELDS
  }
}
